const globalTranslations = {
  no: {
    visitors: 'visitor(s)',
    enter: 'Enter',
    cancel: 'Avbryt',
    accept: 'Bekreft',
    done: 'Ferdig',
    pleaseadddetails: 'Ufullstendig interaksjon',
    stop: 'Stop',
    relate: 'Knytt sammen',
    unrelate: 'Unrelate',
    back: 'Tilbake',
    send: 'Send',
    save: 'Lagre',
    add: 'Legg til',
    new: 'Ny',
    remove: 'Fjerne',
    edit: 'Rediger',
    continue: 'Fortsett',
    ready: 'Klar',
    start: 'Start',
    update: 'Oppdater',
    next: 'Neste',
    previous: 'Forrige',

    missingDetails: '',
    missingFloorplan: '',
    missingInteractions: '',
    missingDoorway: 'Define a at least one doorway',
    missingSurvey: '',
    missingSurveyQuestions: '',
    studyNoLongerDraft: 'Studiestatus forhindrer endringer',
  },
  sv: {
    visitors: 'visitor(s)',
    enter: 'Enter',
    cancel: 'Avbryt',
    done: 'Färdig',
    pleaseadddetails: 'Incomplete interaction',
    stop: 'Stop',
    relate: 'Knytt sammen',
    unrelate: 'Unrelate',
    back: 'Tillbaka',
    save: 'Spara',
    add: 'Lägg till',
    new: 'Ny',
    remove: 'Ta bort',
    edit: 'Redigera',
    update: 'Oppdater',
    continue: 'Fortsätt',
    start: 'Start',
    next: 'Nästa',

    missingDetails: '',
    missingFloorplan: '',
    missingInteractions: '',
    missingDoorway: 'Define a at least one doorway',
    missingSurvey: '',
    missingSurveyQuestions: '',
    studyNoLongerDraft: 'Study status prevents changes',
  },
  en: {
    visitors: 'visitor(s)',
    enter: 'Enter',
    cancel: 'Cancel',
    accept: 'Accept',
    done: 'Done',
    pleaseadddetails: 'Incomplete interaction',
    stop: 'Stop',
    relate: 'Relate',
    unrelate: 'Unrelate',
    back: 'Back',
    send: 'Send',
    save: 'Save',
    add: 'Add',
    remove: 'Remove',
    edit: 'Edit',
    new: 'New',
    continue: 'Continue',
    ready: 'Ready',
    start: 'Start',
    update: 'Update',
    next: 'Next',
    previous: 'Previous',

    missingDetails: 'Define all mission details',
    missingFloorplan: 'Define a floorplan',
    missingDoorway: 'Define a at least one doorway',
    missingInteractions: 'Define at least one action and resource',
    missingSurvey: 'Definen at least one survey',
    missingSurveyQuestions: 'Define at least one question in your survey',
    studyNoLongerDraft: 'Study status prevents changes',
  },
}

export { globalTranslations }
