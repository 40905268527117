<template>
  <div v-if="state.message" class="message fadein fixed mt-11 p-3 max-w-4xl flex flex-col items-center rounded-md text-white" :class="messageStyle()">
    <p v-if="state.message.type === DialogMessageType.Error" class="text-md font-bold">{{ state.message.title }}</p>
    <p class="text-sm">{{ state.message.message }}</p>
  </div>
</template>

<script setup lang="ts">
  import { reactive, watch } from 'vue'
  import { DialogMessage, DialogMessageType } from '@/composition/dialog'
  import useDialogStore from '@/composition/dialog'

  const dialogStore = useDialogStore()

  const state = reactive({
    message: {} as DialogMessage | null,
    isProcessing: false,
    isEmptied: false,
  })

  watch(dialogStore.state, (storeState) => {
    if (!state.isEmptied) {
      if (!state.isProcessing) {
        const message = dialogStore.getters.currentMessage()
        if (message) handleIncomingMessages(message)
      } else {
        // multiple messages at once
        let buffer = storeState.messages
        for (let message of buffer) {
          setTimeout(() => {
            if (message) handleIncomingMessages(message)
          }, message.duration)
        }
        dialogStore.actions.empty()
        state.isEmptied = true // remove all processed messages
        setTimeout(() => {
          state.isEmptied = false
        }, 2000)
      }
    }
  })

  function handleIncomingMessages(message: DialogMessage) {
    const currentMessage = message
    if (currentMessage) {
      // dialogStore has messages
      state.message = currentMessage
      state.isProcessing = true
      // NOTE: this is unfortunately necessary because the state would not update correctly otherwise
      setTimeout(() => {
        state.message = null
        state.isProcessing = false
      }, currentMessage.duration)
      if (message.callback) message.callback()
    }
  }

  function messageStyle() {
    if (state.message) {
      switch (state.message.type) {
        case DialogMessageType.Information:
          return 'dialog-info'
        case DialogMessageType.Success:
          return 'dialog-success'
        case DialogMessageType.Warning:
          return 'dialog-warn'
        case DialogMessageType.Error:
          return 'dialog-err'
      }
    }
  }
</script>

<style scoped>
  .message {
    z-index: 101;
  }
</style>
