import { createI18n } from 'vue-i18n'
import { globalTranslations } from './translations'

let locale = 'en'

switch (navigator.language) {
  case 'nb_NO':
  case 'nb-NO':
  case 'no':
  case 'nb':
    locale = 'no'
    break
  case 'nn_NO':
  case 'nn-NO':
  case 'nn':
    locale = 'nn'
    break
  case 'sv_SE':
  case 'sv-SE':
  case 'sv':
    locale = 'sv'
    break
  case 'en-AU':
  case 'en-GB':
  case 'en-US':
  case 'en_AU':
  case 'en_GB':
  case 'en_US':
  case 'en':
    locale = 'en'
    break
}

const i18n = createI18n({
  locale,
  globalInjection: true,
  fallbackLocale: {
    'nb-NO': ['no'],
    nb: ['no'],
    'nn-NO': ['no'],
    sv: ['sv'],
    nn: ['no'],
    'en-AU': ['en'],
    'en-GB': ['en'],
  },
  legacy: false,
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  fallbackWarn: false,
  missingWarn: false,
  messages: globalTranslations,
})

export { i18n }
