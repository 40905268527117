<!-- Copyright 2020, 2021 Richard Nesnass, Sharanya Manivasagam and Ole Smørdal

 This file is part of VIVA.

 VIVA is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 VIVA is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with VIVA.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <component
    :is="tag"
    :type="type"
    :disabled="disableButton"
    :class="[buttonClass, colourVariants]"
    :variant="variant"
    :variant-type="variantType"
    :size="size"
    :href="to"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
  import { ref, computed } from 'vue'

  const props = defineProps({
    tag: {
      type: String,
      default: 'button',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    variantType: {
      type: String,
      default: 'normal',
    },
    size: {
      type: String,
      default: 'xs',
    },
    rounded: {
      type: String,
      default: 'medium',
    },
    type: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: '',
    },
  })

  const loading = ref(false)

  const disableButton = computed(() => {
    return props.disabled
  })

  const buttonClass = computed(() => {
    return {
      'base-spinner': loading.value,
      'cursor-not-allowed': disableButton.value,
      'base-button inline-flex align-middle align-items-center justify-center font-medium focus:outline-none border-2': true,

      'rounded-lg': props.rounded === 'medium',
      'rounded-full': props.rounded === 'large',

      'px-6 py-3': props.size == 'normal',
      'px-4 py-2': props.size == 'small',
      'px-2 py-1': props.size == 'xs',
    }
  })

  const colourVariants = computed(() => {
    switch (props.variant) {
      case 'primary':
        switch (props.variantType) {
          case 'normal':
            switch (disableButton.value) {
              case true:
                return 'border-gray-200 bg-gray-300 text-white'
              default:
                break
            }
            return 'border-seez-grey-455 bg-seez-grey-450 hover:bg-seez-grey-500 hover:border-seez-grey-500 text-white'
          case 'outline':
            return 'border-gray-200 text-seez-grey-450 hover:text-seez-grey-900'
          default:
            break
        }
        break
      case 'danger':
        switch (props.variantType) {
          case 'normal':
            switch (disableButton.value) {
              case true:
                return 'border-red-300 bg-red-300 text-white'
              default:
                break
            }
            return 'border-red-600 bg-red-600 hover:bg-red-700 hover:border-red-700 text-white'
          case 'outline':
            return 'border-gray-200 text-red-500 hover:text-red-600'
          default:
            break
        }
        break

      case 'warning':
        switch (props.variantType) {
          case 'normal':
            return 'border-orange-600 bg-orange-600 hover:bg-orange-700 hover:border-orange-700 text-white'
          default:
            break
        }
        break

      case 'success':
        switch (props.variantType) {
          case 'normal':
            switch (disableButton.value) {
              case true:
                return 'border-green-300 bg-green-300 text-white'
              default:
                break
            }
            return 'border-green-600 bg-green-600 hover:bg-green-700 hover:border-green-700 text-white'
          case 'outline':
            return 'border-2 border-gray-200 text-green-500 hover:text-green-700'
          default:
            break
        }
        break

      case 'white':
        switch (props.variantType) {
          case 'normal':
            return 'border-white bg-white text-blue-600 hover:text-blue-800'
          default:
            break
        }
        break

      case 'secondary':
        switch (props.variantType) {
          case 'outline':
            return 'border-gray-300 text-gray-500 hover:text-blue-500'
          default:
            break
        }
        break

      default:
        break
    }
    return ''
  })
</script>

<style scoped></style>
