/* import { createApp } from 'vue'
import App from '@/App.vue'
import '@/index.css'

createApp(App).mount('#app')

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    WkWebView: any
    handleOpenURL: unknown
  }
  interface String {
    toPascalCase(): string
    toCamelCase(): string
    padZero(length: number): string
  }
  interface MediaFile {
    localURL: string
  }
}

String.prototype.toPascalCase = function () {
  const text = this.valueOf().replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''))
  return text.substr(0, 1).toUpperCase() + text.substr(1)
} */

/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */

import { createApp, DirectiveBinding } from 'vue'
import { i18n } from './i18n'
import { DraggablePlugin } from '@braks/revue-draggable'
import router from './router'
import App from '@/App.vue'
import 'vue-multiselect/dist/vue-multiselect.css'
import '@vuepic/vue-datepicker/dist/main.css'
import '@vueform/toggle/themes/default.css'
//import '@/vue-flow-form-common.css'
import '@ditdot-dev/vue-flow-form/dist/vue-flow-form.css'
import '@/vue-flow-form-purple-theme.css'
import './index.css'
import useAppStore from './store/useAppStore'
import useDialogStore, { DialogMessageType } from './composition/dialog'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    WkWebView: any
    handleOpenURL: unknown
    AudioContext: typeof AudioContext
    webkitAudioContext: typeof AudioContext
    playSound: (id: string, loop: boolean) => void
  }
  interface String {
    toPascalCase(): string
    toCamelCase(): string
    padZero(length: number): string
  }
  interface MediaFile {
    localURL: string
  }
  interface JSON {
    parseWithDate(json: string): Date
  }
  // this variable will be set by createjs
  // declare let Media: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}

String.prototype.toPascalCase = function () {
  const text = this.valueOf().replace(/[-_\s.]+(.)?/g, (_, c: string) => (c ? c.toUpperCase() : ''))
  return text.substr(0, 1).toUpperCase() + text.substr(1)
}

String.prototype.toCamelCase = function () {
  const text = this.valueOf().replace(/[-_\s.]+(.)?/g, (_, c: string) => (c ? c.toUpperCase() : ''))
  return text.substr(0, 1).toLowerCase() + text.substr(1)
}

String.prototype.padZero = function (length: number): string {
  let s = String(this)
  while (s.length < length) {
    s = '0' + s
  }
  return s
}

document.documentElement.classList.remove('dark')
localStorage.theme = 'light'

const app = createApp(App)

// Hides an HTML element, but KEEPING THE SPACE it would have used if it were visible (css: Visibility)
// https://www.ryansouthgate.com/2020/01/30/vue-js-v-hide-element-whilst-keeping-occupied-space/
app.directive('hide', (el: HTMLElement, binding: DirectiveBinding) => (el.style.visibility = binding.value ? 'hidden' : ''))

const { actions: appActions } = useAppStore()
const { actions: dialogActions } = useDialogStore()

// Redirected OAuth login for mobile devices
// Using cordova-plugin-oauth
// Called from a callback URL like
// com.example.foo://oauth_callback?code=b10a8db164e0754105b7a99be72e3fe5
// it would be received in JavaScript like this:
window.addEventListener('message', function (event: Event & { data: string | number, origin: string }) {
  const url = event.data.toString()
  if (url.match(/^oauth::/)) {
    if (event.origin) console.log(`Login callback event origin: ${event.origin}`)
    const data = JSON.parse(url.substring(7)) as { mode: string, code: string }
    if (data.mode == 'login' && data.code && data.code !== 'undefined') {
      // The JWT will be sent with future requests to authenticate Mobile users in case the session has expired
      localStorage.setItem('jwt', data.code)
      void appActions.tokenLogin()
    }
  }
})

const handleError = (message: string) => {
  if (import.meta.env.DEV) {
    dialogActions.pushMessage('Application Error', DialogMessageType.Error, message, 4000)
  }
  console.warn(message)
}

// Catch unhandled errors
window.addEventListener('unhandledrejection', function (event: PromiseRejectionEvent) {
  const message = `Uncaught promise. ${event.reason}`
  handleError(message)
})

if (!import.meta.env.DEV) {
  app.config.errorHandler = (err) => {
    const error = err as Error
    const message = `${error.message}`
    handleError(message)
  }
}
// Catch errors sent via utilities.js error function
window.addEventListener('kmerror', ((event: CustomEvent<Error>) => {
  //do something
  const error = event.detail
  if (error) {
    const message = error.toString()
    handleError(message)
  }
}) as EventListener)

// Register Draggables
// https://github.com/bcakmakoglu/revue-draggable
app.use(DraggablePlugin)

// Bootstrap the Vue app when called
const initialiseApp = () => {
  app.use(router).use(i18n).mount('#app')
}

initialiseApp()
