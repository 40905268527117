/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */
import { isRef, ref, Ref } from 'vue'
import MersenneTwister from 'mersenne-twister'
import { User } from '@models/index.ts'
interface SoundControl {
  soundList: (string | HTMLAudioElement | Media)[]
  soundTimeout?: NodeJS.Timeout
  currentSound?: HTMLAudioElement | Media
  forceStop: boolean
  endSoundCallback?: () => unknown
}

const soundControl: SoundControl = {
  soundList: [],
  soundTimeout: undefined,
  currentSound: undefined,
  forceStop: false,
  endSoundCallback: () => ({}),
}

/**
 * Wrap a variable of specified type in a Vue Ref
 * @param element to wrap
 * @returns wrapped element
 */
const wrap = <T>(element: Ref<T> | T): Ref<T> => {
  if (isRef(element)) {
    return element
  }
  return ref(element) as Ref<T>
}

/**
 * Convert a path for use in iOS Cordova
 * @param path
 * @returns converted path
 */
const convertFilePath = (path: string): string => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  return window.WkWebView.convertFilePath(path)
}

/**
 * Shuffle the given array and return a new array
 * @param itemsArray An array of any type
 * @returns a new array of the same type
 */
const shuffleItems = <T>(itemsArray: Array<T>): Array<T> => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const generator = new MersenneTwister()
  const indexArray = itemsArray.map((item, index: number) => index)
  let currentIndex = indexArray.length,
    temporaryValue,
    randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    randomIndex = Math.floor(generator.random() * currentIndex)
    currentIndex -= 1
    // And swap it with the current element.
    temporaryValue = indexArray[currentIndex]
    indexArray[currentIndex] = indexArray[randomIndex]
    indexArray[randomIndex] = temporaryValue
  }
  return indexArray.map((index) => itemsArray[index])
}

// https://stackoverflow.com/questions/3552461/how-to-format-a-javascript-date
/**
 * Format a date object into a string including day, month name, year and time
 * @param date
 * @returns string
 */
const dateToFormattedString = (date?: Date): string => {
  if (!date) return 'X'
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  const day = date.getDate()
  const monthIndex = date.getMonth()
  const year = date.getFullYear()

  let hours = date.getHours().toString()
  let mins = date.getMinutes().toString()

  hours = hours.length == 1 ? '0' + hours : hours
  mins = mins.length == 1 ? '0' + mins : mins

  return day + ' ' + monthNames[monthIndex] + ' ' + year + ' | ' + hours + ':' + mins
}

/// <summary>
/// Creates a new filter that processes dates and also delegates to a chain filter optionaly.
/// </summary>
/// <param name="chainFilter" type="Function">property name that is parsed</param>
/// <returns type="Function">returns a new chainning filter for dates</returns>
const createDateParser = function () {
  const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.{0,1}\d*))(?:Z|(\+|-)([\d|:]*))?$/
  //const reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/
  return function (key: string, value: unknown) {
    let parsedValue = value
    if (typeof value === 'string') {
      const a = reISO.exec(value)
      if (a) {
        parsedValue = new Date(value)
      }
    }
    return parsedValue
  }
}

export function parseWithDate(json: string): unknown {
  // JSON.parseWithDate =
  /// <summary>
  /// Wrapper around the JSON.parse() function that adds a date
  /// filtering extension. Returns all dates as real JavaScript dates.
  /// </summary>
  /// <param name="json" type="string">JSON to be parsed</param>
  /// <returns type="any">parsed value or object</returns>
  const parse = JSON.parse
  try {
    const data = JSON.stringify(json)
    const res: unknown = parse(data, createDateParser())
    return res
  } catch (e) {
    // orignal error thrown has no error message so rethrow with message
    throw new Error('JSON content could not be parsed')
  }
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 *
 * @param {number} min
 * @param {number} max
 * @return {*}  {number}
 */
function getRandomInt(min: number, max: number): number {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const generator = new MersenneTwister()
  min = Math.ceil(min)
  max = Math.floor(max)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  return Math.floor(generator.random() * (max - min + 1)) + min
}

function capitalizeFirstLetter(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

// Filter undefined values from an array of T
function filterUndef<T>(ts: (T | undefined)[]): T[] {
  return ts.filter((t: T | undefined): t is T => !!t)
}

// Random UUID. See https://gist.github.com/jed/982883
/**
 * Generate a ranom ID
 */
const uuid = (a = ''): string =>
  a
    ? /* eslint-disable no-bitwise */
      ((Number(a) ^ (Math.random() * 16)) >> (Number(a) / 4)).toString(16)
    : `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, uuid)

const hasMinimumRole = (user: User): boolean => {
  return !user
  /*   switch (requestedRole) {
    case USER_ROLE.user:
      return true
    case USER_ROLE.monitor:
      return user.profile.role === USER_ROLE.monitor || user.profile.role === USER_ROLE.admin || user.profile.role === USER_ROLE.logs ? true : false
    case USER_ROLE.admin:
      return user.profile.role === USER_ROLE.admin || user.profile.role === USER_ROLE.logs ? true : false
    case USER_ROLE.logs:
      return user.profile.role === USER_ROLE.logs ? true : false
    default:
      return false
  } */
}

/**
 * Dispatch a regular error as an 'kmerror'
 * @param error
 */
const emitError = (error: Error): void => {
  const e = new CustomEvent<Error>('kmerror', {
    detail: error,
  })
  window.dispatchEvent(e)
}

/**
 * Async: Wait a number of milliseconds before resolving
 * @param ms length of time to wait (milliseconds)
 * @returns void
 */
const wait = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
}

const createSound = (url: string, callback?: () => unknown) => {
  const cb = () => {
    if (callback) callback()
  }

  // This block uses cordova-plugin-media to play audio if running on a mobile device i.e. window.cordova !== undefined
  // However, that plugin seems to only support one audio at a time. Therefore playing a sound will cancel audio recording
  /* let sound: HTMLAudioElement | Media
  if (window.cordova) {
    sound = new Media(url, cb)
  } else {
    sound = new Audio(url)
    sound.addEventListener('ended', cb)
  } */

  // This block uses only HTMLAudioElement to play sounds
  const sound = new Audio(url)
  sound.addEventListener('ended', cb)
  return sound
}

const playSounds = (soundFiles?: (string | HTMLAudioElement | Media)[], delay?: number, callback?: () => unknown): void => {
  if (soundFiles) {
    soundControl.soundList = [...soundFiles]
    soundControl.forceStop = false // Reset this when we play a new list next time
  }
  if (callback) soundControl.endSoundCallback = callback

  const s = soundControl.soundList.shift()
  if (s) {
    if (typeof s === 'string') {
      try {
        soundControl.currentSound = createSound(s, playSounds)
      } catch (error) {
        console.log(`Error creating sound ${s}: ${(error as Error).message}`)
        playSounds()
      }
    } else {
      soundControl.currentSound = s
    }
    soundControl.soundTimeout = setTimeout(() => {
      try {
        if (soundControl.currentSound) void soundControl.currentSound.play()
      } catch (e) {
        const error = e as Error
        console.log(`Error playing sound: ${error.message}`)
        playSounds()
      }
      return
    }, delay || 500)
  } else if (soundControl.soundList.length) playSounds()
  else if (soundControl.endSoundCallback && !soundControl.forceStop) soundControl.endSoundCallback()
}

const stopSounds = (): void => {
  soundControl.forceStop = true // Prevent callback being called when Media is stopped (rather than 'ended')
  if (soundControl.soundTimeout) clearTimeout(soundControl.soundTimeout)
  if (soundControl.currentSound && soundControl.currentSound instanceof Media) {
    soundControl.currentSound.stop()
  } else if (soundControl.currentSound && soundControl.currentSound instanceof HTMLAudioElement) {
    soundControl.currentSound.pause()
    soundControl.currentSound.currentTime = 0
  }
}

enum WINDOW_SIZES {
  OLD_HEIGHT = 768,
  OLD_WIDTH = 1024,
  /*  NEW_HEIGHT = window.innerHeight,
  NEW_WIDTH = window.innerWidth, */
  NEW_HEIGHT = 768,
  NEW_WIDTH = 1024,
  SCALE = Math.min(NEW_WIDTH / OLD_WIDTH, NEW_HEIGHT / OLD_HEIGHT),
  SCALE_Y = NEW_HEIGHT / OLD_HEIGHT,
  SCALE_X = NEW_WIDTH / OLD_WIDTH,
}

const scaleContent = (): number => {
  return Math.min(WINDOW_SIZES.NEW_WIDTH / WINDOW_SIZES.OLD_WIDTH, WINDOW_SIZES.NEW_HEIGHT / WINDOW_SIZES.OLD_HEIGHT)
}

interface Coordinates {
  h: number
  w: number
  x: number
  y: number
}
const getCoordinates = (coordinates: Coordinates): Coordinates => {
  const scaledCoordinates = {
    h: coordinates.h * WINDOW_SIZES.SCALE_Y,
    w: coordinates.w * WINDOW_SIZES.SCALE_X,
    x: coordinates.x * WINDOW_SIZES.SCALE_X,
    y: coordinates.y * WINDOW_SIZES.SCALE_Y,
  }
  return scaledCoordinates
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getDefaultOverlayProperties = () => {
  return {
    compulsory: false,
    description: '',
    start: {
      x: 0,
      y: 0,
      w: 0,
      h: 0,
      z: 1,
    },
    transition: {
      x: 0,
      y: 0,
      scale: 1,
      duration: 1,
    },
    map: {
      x: 0,
      y: 0,
      w: 0,
      h: 0,
    },
    pointer: {
      x: 0,
      y: 0,
      delay: 0,
      retain: true,
    },
    show_highlight: false,
    highlight_location: {
      x: 0,
      y: 0,
    },
    highlight_size: {
      width: 0,
      height: 0,
    },
    opacity: 1,
    visible_before: false,
    visible_after: false,
    auto_return: false,
    allow_return: false,
    auto_start: false,
    timeout: 0,
    delay: 0,
    active: false,
    completed: false,
    audio: false,
  }
}

export {
  uuid,
  filterUndef,
  dateToFormattedString,
  capitalizeFirstLetter,
  getRandomInt,
  wrap,
  convertFilePath,
  wait,
  hasMinimumRole,
  shuffleItems,
  emitError,
  getCoordinates,
  scaleContent,
  WINDOW_SIZES,
  getDefaultOverlayProperties,
  playSounds,
  stopSounds,
}
