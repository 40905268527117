/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from 'vue-router'

// Common / Portal
const Landing = () => import('@/views/landing/landingPage.vue')
const Privacy = () => import('@/views/landing/PrivacyStatement.vue')
const PortalDashboard = () => import('@/views/portal/portalDashboard.vue')

const DashboardHome = () => import('@/views/portal/dashboardHome.vue')
const DashboardCreateStudy = () => import('@/views/portal/dashboardCreateStudy.vue')
const DashboardStudies = () => import('@/views/portal/dashboardStudies.vue')
const DashboardResults = () => import('@/views/portal/dashboardResults.vue')
const DashboardSharing = () => import('@/views/portal/dashboardSharing.vue')
const Profile = () => import('@/views/portal/dashboardProfile.vue')
const Help = () => import('@/views/portal/dashboardHelp.vue')

const MissionPage = () => import('@/views/portal/create/missionPage.vue')
const FloorplanPage = () => import('@/views/portal/create/floorplanPage.vue')
const InteractionPage = () => import('@/views/portal/create/interactionPage.vue')
const SurveyPage = () => import('@/views/portal/create/surveyPage.vue')
const SummaryPage = () => import('@/views/portal/create/summaryPage.vue')

// Tablet
const Tracking = () => import('@/views/tracking/trackingView.vue')
const Connect = () => import('@/views/tracking/connect.vue')
const Survey = () => import('@/views/tracking/surveyView.vue')

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Landing',
    component: Landing,
  },
  {
    path: '/track',
    redirect: '/track/connect',
    name: 'Track',
    children: [
      {
        path: 'connect/:studyID?',
        name: 'Connect',
        props: true,
        component: Connect,
      },
      {
        path: 'tracking',
        name: 'Tracking',
        component: Tracking,
      },
      {
        path: 'survey',
        name: 'Survey',
        props: true,
        component: Survey,
      },
    ],
  },
  {
    path: '/dashboard',
    redirect: '/dashboard/home',
    name: 'Dashboard',
    component: PortalDashboard,
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /dashboard/home is matched
        path: 'home',
        name: 'Home',
        component: DashboardHome,
      },
      {
        path: 'profile',
        name: 'Profile',
        component: Profile,
      },
      {
        path: 'help',
        name: 'Help',
        component: Help,
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /dashboard/create is matched
        path: 'create',
        name: 'Create',
        component: DashboardCreateStudy,
        children: [
          {
            path: 'mission',
            name: 'MissionPage',
            props: true,
            component: MissionPage,
          },
          {
            path: 'interactions',
            name: 'InteractionPage',
            component: InteractionPage,
          },
          {
            path: 'floorplan',
            name: 'FloorplanPage',
            component: FloorplanPage,
          },
          {
            path: 'survey',
            name: 'SurveyPage',
            component: SurveyPage,
          },
          {
            path: 'summary',
            name: 'SummaryPage',
            component: SummaryPage,
          },
        ],
      },
      {
        path: 'studies',
        name: 'Studies',
        component: DashboardStudies,
      },
      {
        path: 'results',
        name: 'Results',
        component: DashboardResults,
      },
      {
        path: 'sharing',
        name: 'Sharing',
        component: DashboardSharing,
      },
    ],
  },
  { path: '/index.html', redirect: '/' },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
  },
]

// Apparently Cordova does not work in browser 'history' mode
const useHashHistory = false //baseUrl.includes('engagelab') || baseUrl.includes('dev.kapteinmorf')
const baseURL = import.meta.env.BASE_URL || 'https://seezapp.azurewebsites.net/'
const routerType = useHashHistory // As engagelab server uses proxying for multiple apps, this is the easiest way..
  ? createWebHashHistory()
  : createWebHistory(baseURL)
console.log(`Hash history mode: ${useHashHistory ? 'on' : 'off'}`)

const router = createRouter({
  history: routerType,
  routes,
})

router.beforeEach(() => {
  // If we reloaded after authentication, we might need to continue logging in
  /* if (['tracking', 'connect', 'survey'].some((path) => to.fullPath.includes(path))) {
    // TODO: do we need to check something on the tablet too?
  } else if (!(from.name || to.redirectedFrom?.name) && to.name !== 'Landing' && to.name !== 'Privacy' && to.name !== 'Tracking') {
    return { name: 'Dashboard' }
  } */
})

export default router
